.quill .ql-toolbar {
  border: 0 !important;
}
.quill .ql-container {
  border: 0 !important;
  border-top: 1px solid #d1d5db !important;
}

.ql-editor {
  min-height: 200px;
}
.phone_input {
  height: auto !important;
  border-radius: 9999px !important;
  padding: 0.75rem !important;
  padding-left: 3.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  border-color: rgb(209 213 219) !important;
  width: 100% !important;
  display: block !important;
  margin-top: 0.25rem !important;
}
.react-tel-input .selected-flag {
  padding: 0 0 0 16px !important;
  width: 48px !important;
}
.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag:hover {
  background-color: transparent !important;
}
.phone_flag_dropdown {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
  background-color: #fff !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}
.hoverable--overlay {
  display: none !important;
}
.hoverable:hover .hoverable--overlay {
  display: flex !important;
}

.video {
  transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1)
    scaleY(1.1);
}

.callback-height {
  height: calc(100vh - 65px);
}

/* tag css */
.tags-input:focus {
  outline: transparent;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.tag {
  width: auto;
  height: 32px;
  padding: 0 6px;
  list-style: none;
  margin: 0 8px 8px 0;
}
.tag .tag-title {
  margin-top: 1px;
}
.tag .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #166534;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}
.eai__control {
  border-radius: 9999px !important;
}
.eai__placeholder {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.eai__single-value {
  font-size: 0.875rem !important;
}

.eai__input-container {
  font-size: 0.875rem !important;
  margin: 1px !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.container {
  display: grid;
}

#mainframe {
  height: 800px;
  width: 100%;
  border: none;
  grid-area: 1 / 1;
}

.btn-container {
  grid-area: 1/1;
}

.btn1 {
  position: absolute;
  border-color: #677791;
  border: 10px;
  /* height: 40px; */
  cursor: pointer;
  transition: 0.4s;
  top: -5rem;
  margin-left: 30px;
  font-size: 1rem;
  box-shadow: 0 1rem 2rem 0 rgba(124, 160, 73, 0.15);
  color: #ffffff;
}

.btn2 {
  position: absolute;
  border-color: #677791;
  border: 45px;
  /* height: 40px; */
  cursor: pointer;
  transition: 0.4s;
  top: -5rem;
  margin-left: 460px;
  font-size: 1rem;
  box-shadow: 0 1rem 2rem 0 rgba(124, 160, 73, 0.15);
  color: #ffffff;
}

.shim::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0,
    rgba(233, 233, 233, 0.9) 50%,
    rgba(233, 233, 233, 0.8) 100%
  );
  animation: shimmer 2s ease-out infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}
