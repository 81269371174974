@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  html {
    scroll-behavior: smooth;
  }
  * {
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
    box-sizing: border-box;
    transition: 0.03s ease-in;
  }
  body {
    margin: 0;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: auto;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }
  .selectTag::after {
    content: "\2715";
    margin-left: 0.25rem;
  }
  .country__control {
    border-width: 0 !important;
  }
  .eai-carousel .slide {
    @apply p-1 md:p-0 !important;
  }
  .camera-details .face-list {
    height: calc(100vh - 55px);
  }
  .failed-iframe,
  .loader-screen {
    min-height: calc(100vh - 7.6rem);
  }
  .upload-h-screen {
    min-height: calc(100vh - 3.5rem);
  }
}
